import React from "react"
import WebsiteLayout from "../../components/layout/website/website-layout/website-layout"
import SEO from "../../services/seo"
import { useStaticQuery, graphql } from "gatsby"
import styles from "./index.module.scss"
import Button from "../../components/common/button/button"

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query Page404Query {
      allStrapiMissing {
        edges {
          node {
            title
            content
          }
        }
      }
    }
  `)
  return (
    <WebsiteLayout>
      <SEO title="404: Not found" />
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.tree}></div>
          <h2>{data.allStrapiMissing.edges[0].node.title}</h2>
          <p>{data.allStrapiMissing.edges[0].node.content}</p>
        </div>
      </div>
    </WebsiteLayout>
  )
}

export default NotFoundPage
